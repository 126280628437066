import React from 'react'
import Badge from './Badge'

type SimpleMultiStepProps = {
  steps: number
  currentStep: number
  onChangeStep?: (step: number) => void
  className?: string
  firstStepText?: string
  firstStepDescription?: string
  lastStepText?: string
  lastStepDescription?: string
}

export const SimpleMultiStep = ({
  steps,
  currentStep,
  className = '',
  firstStepText = '',
  firstStepDescription = '',
  lastStepText = '',
  lastStepDescription = '',
}: SimpleMultiStepProps) => {
  return (
    <div className={`simple-multi-step flex flex-col space-y-2 w-full ${className}`}>
      <span className="simple-multi-step-indicator text-xl font-bold">
        {currentStep === 1 && firstStepText
          ? firstStepText
          : currentStep === steps && lastStepText
          ? lastStepText
          : `Step ${currentStep}/${steps}`}
      </span>

      {currentStep === 1 && firstStepDescription ? (
        <div className="simple-multi-step-description text-sm" dangerouslySetInnerHTML={{ __html: firstStepDescription }} />
      ) : currentStep === steps && lastStepText && lastStepDescription ? (
        <div className="simple-multi-step-description text-sm" dangerouslySetInnerHTML={{ __html: lastStepDescription }} />
      ) : (
        <></>
      )}
    </div>
  )
}

type MultiStepProps = {
  steps: number
  currentStep: number
  onChangeStep: (step: number) => void
  className?: string
}

const MultiStep = ({ steps, currentStep, onChangeStep, className = '' }: MultiStepProps) => {
  const onStepClick = (step: number) => {
    onChangeStep(step)
  }

  const isActive = (index: number) => {
    return index === currentStep
  }

  return (
    <div className={`multi-step flex justify-between items-center w-full relative ${className}`}>
      <div className="absolute top-1/2 w-full border-t-[1px] border-neutral z-[1]"></div>
      {Array(steps)
        .fill(null)
        .map((_, i) => (
          <Badge
            value={i + 1}
            onClick={() => onStepClick(i)}
            className="z-[2] border-8 border-background box-content font-semibold"
            backgroundColor={isActive(i) ? 'bg-primary' : 'bg-gray-500'}
            textColor={isActive(i) ? 'text-primaryContrast' : 'text-background'}
            key={i}
          />
        ))}
    </div>
  )
}

export default MultiStep
