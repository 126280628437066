import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { SelectOption } from '../../../util/select-option'
import { HelpTextProps } from '../../HelpText'
import { FormElementErrorMessage, FormElementLabel } from './Input'

export type SelectProps = {
  label?: string
  required?: boolean
  showOptionalIfNotRequired: boolean
  property?: string
  selectOptions?: SelectOption[]
  validators?: any
  errors?: FieldErrors
  register?: UseFormRegister<any>
  value?: any
  onChange?: (...args: any) => void
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  helpText?: HelpTextProps
}

export const Select = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  selectOptions = [],
  validators = {},
  errors = undefined,
  register = (...args) => null,
  value = undefined,
  onChange = (...args) => null,
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  const _onChange = (ev) => {
    setCurrentValue(ev?.target?.value)
    onChange(ev)
  }

  return (
    <div className="form-select flex flex-col mb-4 relative">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <select
        name={property}
        {...register(property, { required: required, validate: validators })}
        className={`form-select-select flex w-full bg-form text-formContrast text-sm border-[.5px] border-formBorder rounded-md h-[42px] pl-4 pr-8 py-[10px] focus:outline-none focus:border-primary appearance-none cursor-pointer transition hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder ${
          errors != null ? 'border-red-500' : ''
        }`}
        onChange={_onChange}
        value={currentValue}
      >
        {selectOptions.map((option, index) => (
          <option key={index} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>

      <FontAwesomeIcon icon={faChevronDown} className="absolute right-3 bottom-[13px] opacity-60 pointer-events-none" />

      {additionalActionText && (
        <div
          className="form-select-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}

      <FormElementErrorMessage errors={errors} />
    </div>
  )
}
