import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence } from 'framer-motion'
import React, { ReactNode, useState } from 'react'
import BottomSheet from './core/BottomSheet'

export type HelpTextProps = {
  displayLabel?: boolean
  displayIcon?: boolean
  title: string
  description: string | ReactNode
  children?: any
}
const HelpText = ({ displayLabel = false, displayIcon = true, title, description, children = null }: HelpTextProps) => {
  const [show, setShow] = useState(false)

  const onClick = (e: React.MouseEvent, visible: boolean) => {
    e?.stopPropagation()
    setShow(visible)
  }

  return (
    <>
      <div className="help-text flex items-center space-x-1 cursor-help" onClick={(e) => onClick(e, true)}>
        {displayLabel && <span className="help-text-title">{title}</span>}
        {displayIcon && <FontAwesomeIcon className="help-text-icon opacity-80 p-[1px]" icon={faInfoCircle} />}
        {children && children}
      </div>
      <AnimatePresence>
        {show && (
          <BottomSheet title={title} onClose={(e) => onClick(e, false)} addCloseIcon>
            <pre className="help-text-description text-sm font-sans whitespace-pre-wrap">{description}</pre>
          </BottomSheet>
        )}
      </AnimatePresence>
    </>
  )
}

export default HelpText
