import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type IconBadgeProps = BadgeProps & {
  icon: IconProp
  iconColor?: string
}
export const IconBadge = ({
  icon,
  iconColor = 'text-yellow-400',
  value: count,
  className = '',
  backgroundColor = 'bg-gray-500 bg-opacity-10',
  textColor = 'text-gray-500',
  pulsating = false,
  onClick = null,
}: IconBadgeProps) => {
  return (
    <div
      className={`icon-badge rounded-xl px-2 py-[6px] space-x-1 flex justify-center items-center ${
        pulsating ? 'animate-pulse' : ''
      } ${backgroundColor} ${textColor} ${className}`}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} className={'icon-badge-icon ' + iconColor} />
      <span className="icon-badge-count text-xs font-semibold leading-none">{count}</span>
    </div>
  )
}

export type BadgeProps = {
  value: number | string
  backgroundColor?: string
  textColor?: string
  className?: string
  pulsating?: boolean
  onClick?: (...args) => any
}

const Badge = ({
  value: count,
  className = '',
  backgroundColor = 'bg-primary',
  textColor = 'text-primaryContrast',
  pulsating = false,
  onClick = null,
}: BadgeProps) => {
  return (
    <div
      className={`badge rounded-full w-6 h-6 flex justify-center items-center leading-none text-xs font-medium ${
        pulsating ? 'animate-pulse' : ''
      } ${backgroundColor} ${textColor} ${className}`}
      onClick={onClick}
    >
      {count}
    </div>
  )
}

export default Badge
