export const FadeIn = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.25,
    },
  },
}

export const FadeIn3SecDelayed = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 0.7,
    transition: {
      duration: 0.25,
      delay: 3,
    },
  },
}

export const FadeInOut = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.25 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.25 },
  },
}

export const FadeInOutDelayed = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.1, delay: 0.5 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1 },
  },
}

export const FadeAndSlideLeftInOut = {
  initial: { opacity: 0, x: '-100%' },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'linear',
    },
  },
  exit: { opacity: 0, x: '-100%' },
}

export const SideNavAnimation = {
  initial: {
    x: '100%',
  },
  show: {
    x: 0,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
  hide: {
    x: '100%',
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
}

export const FadeAndSlideBottomInOut = {
  initial: { opacity: 0, y: '100%' },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'linear',
    },
  },
  exit: { opacity: 0, y: '100%' },
}

export const SlideTopInOut = {
  initial: { y: '-100%' },
  animate: {
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'linear',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      duration: 0.2,
      ease: 'linear',
    },
  },
}

export const StakeSelectorAnimation = {
  initial: { y: '-48px' },
  animate: {
    y: 0,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-48px',
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
}

export const BonusBannerAnimation = {
  initial: { y: '-48px' },
  animate: {
    y: 0,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-48px',
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
}

export const BetslipToggleAnimation = {
  initial: { opacity: 0, y: '100%', scaleX: 0 },
  animate: {
    opacity: 1,
    y: 0,
    scaleX: 1,
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
      delay: 0.25,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.15,
      ease: 'easeInOut',
    },
  },
}

export const SlideBottomInOut = {
  initial: { y: '100%' },
  animate: {
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'linear',
    },
  },
  exit: {
    y: '100%',
    transition: {
      duration: 0.2,
      ease: 'linear',
    },
  },
}

export const BetslipAnimation = {
  initial: {
    y: '100%',
  },
  show: {
    y: 0,
    transition: {
      duration: 0.25,
      delay: 0.15,
      ease: 'easeInOut',
    },
  },
  hide: {
    y: '100%',
    transition: {
      duration: 0.25,
      ease: 'easeInOut',
    },
  },
}

export const BetslipAnimationDesktop = (reduceMotion: boolean) => {
  return {
    collapsed: {
      height: '52px',
      transition: {
        duration: reduceMotion ? 0 : 0.25,
        ease: 'easeInOut',
      },
    },
    expanded: {
      height: 'auto',
      transition: {
        duration: reduceMotion ? 0 : 0.25,
        ease: 'easeInOut',
      },
    },
  }
}

export const StaggerChildren = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
    },
  },
  exit: {
    opacity: 0,
  },
}

export const StaggeredChild = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

export const Accordion = {
  initial: { height: 0, overflow: 'hidden' },
  animate: { height: 'auto' },
  exit: { height: 0, overflow: 'hidden' },
}
