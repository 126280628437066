import { Subject } from 'rxjs'
import { filter } from 'rxjs/operators'

export enum AlertType {
  Success = 'Success',
  Error = 'Error',
  Info = 'Info',
  Warning = 'Warning',
}

export type Alert = {
  itemId?: number
  message: string
  type: AlertType
  duration?: number
  createdAt?: Date
} & AlertOptions

export type AlertOptions = {
  alertContainerId?: string
  autoClose?: boolean
  keepAfterRouteChange?: boolean
}

const alertSubject = new Subject()
const defaultId = 'default-alert'

// enable subscribing to alerts observable
const onAlert = (alertContainerId = defaultId) => {
  return alertSubject.asObservable().pipe(filter((x: Alert) => x && x.alertContainerId === alertContainerId))
}

// convenience methods
const success = (message: string, options?: AlertOptions) => {
  alert({ ...options, type: AlertType.Success, message })
}

const error = (message: string, options?: AlertOptions) => {
  alert({ ...options, type: AlertType.Error, message })
}

const info = (message: string, options?: AlertOptions) => {
  alert({ ...options, type: AlertType.Info, message })
}

const warn = (message: string, options?: AlertOptions) => {
  alert({ ...options, type: AlertType.Warning, message })
}

// core alert method
const alert = (alert: Alert) => {
  alert.createdAt = new Date()
  alert.alertContainerId = alert.alertContainerId || defaultId
  alert.autoClose = alert.autoClose === undefined ? true : alert.autoClose
  alertSubject.next(alert)
}

// clear alerts
const clear = (alertContainerId = defaultId) => {
  alertSubject.next({ alertContainerId })
}

export const alertService = {
  onAlert,
  success,
  error,
  info,
  warn,
  alert,
  clear,
}