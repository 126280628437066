import { Currency } from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { usePaymentSettings } from '../../../lib/content'
import { getIntlCurrencySlot, getIntlSeparator, parseIntlNumberFormat } from '../../../util/internationalization'
import { floorNumber, formatMoney } from '../../../util/number'
import { Button } from '../../core/Button'
import { CustomMessageType } from '../FormBuilder'
import { FormElementErrorMessage, FormElementLabel, TextInputProps } from './Input'

type AmountSelectorItemProps = {
  amount: number
  setAmount: (amount: number) => void
  isSelected: boolean
  currency: Currency
}

const AmountSelectorItem = ({ amount, setAmount, isSelected, currency }: AmountSelectorItemProps) => {
  return (
    <Button
      className={`amount-selector-item flex justify-center items-center !px-2 py-[10px] w-28 h-full rounded-md border-[1px] transition ${
        isSelected
          ? 'amount-selector-item-active bg-primary text-primaryContrast border-primary'
          : '!font-medium bg-form text-formContrast border-formBorder hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder'
      }`}
      onClick={() => setAmount(amount)}
    >
      <span className={`amount-selector-item-label flex items-center whitespace-nowrap text-sm font-semibold`}>
        {formatMoney(amount, currency, 0)}
      </span>
    </Button>
  )
}

export const AmountSelectorInput = ({
  label = '',
  property = undefined,
  required = false,
  showOptionalIfNotRequired = true,
  readonly = false,
  register = (...args) => null,
  onChange = (...args) => null,
  setValue = (...args) => null,
  validators = {} as any,
  errors = undefined,
  minValue = undefined,
  maxValue = undefined,
  value = undefined,
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  customPatternErrorMessage = undefined,
  helpText = null,
  showLabel = false,
}: TextInputProps) => {
  const { t } = useTranslation('common')
  const paymentSettings = usePaymentSettings()
  const currency = useUserCurrency()
  const currencySymbol = currency?.symbol ?? currency?.shortSign
  const currencySlot = getIntlCurrencySlot()
  const currencyDecimalSeparator = getIntlSeparator('decimal')
  const currencyPlaceholderString =
    currencySlot === 'start'
      ? currencySymbol + '100' + currencyDecimalSeparator + '00'
      : '100' + currencyDecimalSeparator + '00' + currencySymbol
  const [currentValue, setCurrentValue] = useState(value)
  const [defaultAmounts, setDefaultAmounts] = useState([1, 5, 10])
  const [isHidden, setIsHidden] = useState(true)

  useEffect(() => {
    if (paymentSettings != null) {
      setDefaultAmounts([
        paymentSettings.defaultAmount1 != null ? parseInt(paymentSettings.defaultAmount1) : 1,
        paymentSettings.defaultAmount2 != null ? parseInt(paymentSettings.defaultAmount2) : 5,
        paymentSettings.defaultAmount3 != null ? parseInt(paymentSettings.defaultAmount3) : 10,
      ])
    }
  }, [paymentSettings])

  const updateAmount = (amount: number, hideManualInput: boolean = true) => {
    setIsHidden(hideManualInput)
    setCurrentValue(amount)

    onChange(amount)

    setValue(property, amount, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  const showManualInput = () => {
    setCurrentValue(undefined)
    setIsHidden(false)
  }

  const onManualAmountChange = (ev) => {
    const value = parseIntlNumberFormat(ev?.target?.value?.replace(currencySymbol, ''))

    if (!isNaN(value) && value > 0) {
      updateAmount(floorNumber(value), false)
    } else {
      updateAmount(null, false)
    }
  }

  return (
    <div className="amount-selector flex flex-col mb-4">
      {showLabel && (
        <FormElementLabel
          label={label}
          property={property}
          required={required}
          readonly={readonly}
          showOptionalIfNotRequired={showOptionalIfNotRequired}
          helpText={helpText}
        />
      )}

      <div className="amount-selector-items flex flex-row space-x-4 mb-4 justify-center items-center">
        {defaultAmounts?.map((x, index) => (
          <React.Fragment key={index}>
            <AmountSelectorItem
              amount={x}
              setAmount={updateAmount}
              isSelected={currentValue === x}
              currency={currency}
            />
          </React.Fragment>
        ))}

        <Button
          className={`amount-selector-item-custom flex justify-center items-center !px-2 py-[10px] w-28 h-full rounded-md border-[1px] transition ${
            isHidden
              ? '!font-medium bg-form text-formContrast border-formBorder hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder'
              : 'bg-primary text-primaryContrast border-primary'
          }`}
          onClick={showManualInput}
        >
          <span
            className={`amount-selector-item-custom-label flex items-center whitespace-nowrap text-sm font-semibold`}
          >
            {t('Common.other')}
          </span>
        </Button>
      </div>

      <div
        className={`amount-selector-custom flex space-x-2 items-center bg-form text-formContrast border-[.5px] border-formBorder rounded-md h-[42px] px-4 py-[10px] transition ${
          !readonly
            ? 'focus:border-primary hover:bg-formHover hover:text-formHoverContrast hover:border-formHoverBorder'
            : ''
        } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly ? ' opacity-60' : ''} ${
          isHidden ? 'hidden' : ''
        }`}
      >
        <NumericFormat
          className={`amount-selector-custom-input flex w-full break-all text-sm bg-form text-formContrast focus:outline-none appearance-none`}
          inputMode="decimal"
          value={currentValue}
          onChange={onManualAmountChange}
          onClick={(ev: any) => ev?.target?.select()}
          placeholder={currencyPlaceholderString}
          prefix={currencySlot === 'start' ? currencySymbol : ''}
          suffix={currencySlot === 'end' ? currencySymbol : ''}
          thousandSeparator={getIntlSeparator('group')}
          decimalSeparator={getIntlSeparator('decimal')}
          decimalScale={2}
          fixedDecimalScale
        />

        <input
          type="hidden"
          id={property}
          name={property}
          value={currentValue || ''}
          {...register(property, {
            required: required,
            min: minValue,
            max: maxValue,
            validate: validators,
          })}
        />
      </div>

      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        customPatternErrorMessage={customPatternErrorMessage}
        errors={errors}
        minValue={formatMoney(Number(minValue), currency)}
        maxValue={formatMoney(Number(maxValue), currency)}
      />
    </div>
  )
}
