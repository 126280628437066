import { faCheck, faExclamationTriangle, faEye, faEyeSlash, faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useState } from 'react'
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form'
import useUserCurrency from '../../../hooks/useUserCurrency'
import { formatMoney } from '../../../util/number'
import HelpText, { HelpTextProps } from '../../HelpText'
import { CustomMessageType } from '../FormBuilder'
import { isNullOrEmpty } from '../../../util/string'

export type TextInputProps = {
  label?: string
  property?: string
  placeholder?: string
  required?: boolean
  showOptionalIfNotRequired?: boolean
  register?: UseFormRegister<any>
  setValue?: UseFormSetValue<any>
  getValues?: UseFormGetValues<any>
  trigger?: UseFormTrigger<any>
  validators?: any
  type?: string
  inputType?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
  accept?: string
  errors?: FieldErrors
  minValue?: string | number
  maxValue?: string | number
  autocomplete?: string
  readonly?: boolean
  disabled?: boolean
  minLength?: number
  maxLength?: number
  pattern?: RegExp
  value?: any
  prefix?: string
  postfix?: string
  customMessage?: string
  customMessageType?: CustomMessageType
  customPatternErrorMessage?: string
  onChange?: (args?: any) => void
  onBlur?: (args?: any) => void
  onKeyDownEvent?: (args?: any) => void
  selectOnClick?: boolean
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  additionalActionOnlyIfValid?: boolean
  additionalActionOnlyIfNotEmpty?: boolean
  helpText?: HelpTextProps
  textAlign?: 'text-left' | 'text-center' | 'text-right'
  backgroundColor?: string
  textColor?: string
  showLabel?: boolean
  fixedCountryCallingCode?: any
}

export const TextInput = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  placeholder = undefined,
  register = (...args) => null,
  onChange = (...args) => null,
  onBlur = (...args) => null,
  getValues = (...args) => null,
  onKeyDownEvent = (...args) => null,
  validators = {} as any,
  type = undefined,
  inputType = 'text',
  accept = undefined,
  errors = undefined,
  autocomplete = undefined,
  readonly = false,
  disabled = false,
  minValue = undefined,
  maxValue = undefined,
  minLength = undefined,
  maxLength = undefined,
  pattern = undefined,
  value = undefined,
  prefix = undefined,
  postfix = undefined,
  customMessage = undefined,
  customMessageType = CustomMessageType.Error,
  customPatternErrorMessage = undefined,
  selectOnClick = false,
  additionalActionText = '',
  additionalActionFn = null,
  additionalActionOnlyIfValid = false,
  additionalActionOnlyIfNotEmpty = false,
  helpText = null,
  textAlign = 'text-left',
  backgroundColor = null,
  textColor = null,
  showLabel = true,
}: TextInputProps) => {
  const currency = useUserCurrency()

  const [currentValue, setCurrentValue] = useState(value)
  const [passwordHidden, setPasswordHidden] = useState(true)

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  const _onClick = (ev) => {
    if (selectOnClick) ev?.target?.select()
  }

  const _onChange = (ev) => {
    setCurrentValue(ev?.target?.value)
    onChange(ev)
  }

  const _onKeyDownEvent = (event: any) => {
    onKeyDownEvent(event)
  }

  return (
    <div className="form-input flex flex-grow flex-col">
      {label && showLabel && (
        <FormElementLabel
          label={label}
          property={property}
          required={required}
          showOptionalIfNotRequired={showOptionalIfNotRequired}
          readonly={readonly}
          helpText={helpText}
        />
      )}
      <div
        className={`form-input-inner flex space-x-2 items-center transition ${backgroundColor ? backgroundColor : 'bg-form hover:bg-formHover'} ${
          textColor ? textColor : 'text-formContrast hover:text-formHoverContrast'
        } border-[.5px] border-formBorder hover:border-formHoverBorder rounded-md h-[42px] px-4 py-[10px] ${
          !disabled && !readonly ? 'focus:border-primary' : ''
        } ${errors != null ? 'border-red-500 border-opacity-100' : ''} ${readonly || disabled ? ' opacity-60' : ''}`}
      >
        {prefix && (
          <div className="form-input-prefix text-sm font-semibold opacity-50">
            <span>{prefix}</span>
          </div>
        )}

        <input
          className={`form-input-input flex w-full h-full break-all text-sm bg-transparent focus:outline-none appearance-none ${textAlign}`}
          name={property}
          id={property}
          placeholder={placeholder}
          onClick={_onClick}
          onChange={_onChange}
          onBlur={onBlur}
          type={passwordHidden ? type : 'text'}
          inputMode={inputType}
          accept={accept}
          min={minValue}
          max={maxValue}
          step="any"
          autoComplete={autocomplete}
          readOnly={readonly}
          disabled={disabled}
          {...register(property, {
            required: required,
            min: minValue,
            max: maxValue,
            minLength: minLength,
            maxLength: maxLength,
            pattern: pattern,
            validate: validators,
          })}
          value={currentValue}
          onKeyDown={_onKeyDownEvent}
        />

        {(postfix || type === 'password') && (
          <div className="form-input-postfix text-sm font-semibold text-gray-500">
            {postfix != null && type != 'password' && <span>{postfix}</span>}
            {type === 'password' && (
              <div className="p-1 cursor-pointer" onClick={() => setPasswordHidden(!passwordHidden)}>
                {passwordHidden ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
              </div>
            )}
          </div>
        )}
      </div>

      {additionalActionText &&
        (errors == null || !additionalActionOnlyIfValid) &&
        (!isNullOrEmpty(currentValue) || !additionalActionOnlyIfNotEmpty || (getValues && getValues(property))) && (
          <div
            className="form-input-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer hover:underline"
            onClick={additionalActionFn}
          >
            {additionalActionText}
          </div>
        )}

      <FormElementErrorMessage
        customMessage={customMessage}
        customMessageType={customMessageType}
        customPatternErrorMessage={customPatternErrorMessage}
        errors={errors}
        minLength={minLength}
        maxLength={maxLength}
        minValue={formatMoney(Number(minValue), currency)}
        maxValue={formatMoney(Number(maxValue), currency)}
      />
    </div>
  )
}

export type FormElementLabelProps = {
  label: string
  property?: string
  required?: boolean
  showOptionalIfNotRequired?: boolean
  readonly?: boolean
  helpText?: HelpTextProps
  className?: string
}
export const FormElementLabel = ({
  label,
  property,
  required,
  showOptionalIfNotRequired,
  readonly,
  helpText,
  className = '',
}: FormElementLabelProps) => {
  const { t } = useTranslation()

  return (
    <label htmlFor={property} className={'form-element-label flex items-center space-x-1 text-xs mb-1 ' + className}>
      <span className="opacity-60">
        {label}
        {!required && !readonly && showOptionalIfNotRequired && ' (' + t('common:Common.optional') + ')'}
      </span>
      {helpText && <HelpText {...helpText} />}
    </label>
  )
}

export type FormElementErrorMessageProps = {
  customMessage?: string
  customMessageType?: CustomMessageType
  customPatternErrorMessage?: string
  errors?: FieldErrors
  minValue?: number | string
  maxValue?: number | string
  minLength?: number
  maxLength?: number
}
export const FormElementErrorMessage = ({
  customMessage,
  customMessageType,
  customPatternErrorMessage,
  errors,
  minValue = undefined,
  maxValue = undefined,
  minLength = undefined,
  maxLength = undefined,
}: FormElementErrorMessageProps) => {
  const { t } = useTranslation()

  return (
    <>
      {customMessage && (
        <div>
          {customMessageType === CustomMessageType.Error && (
            <span className="form-element-error-message text-xs text-red-600">
              <FontAwesomeIcon icon={faTimes} className="mr-2" />
              {customMessage}
            </span>
          )}
          {customMessageType === CustomMessageType.Success && (
            <span className="form-element-success-message text-xs text-green-600">
              <FontAwesomeIcon icon={faCheck} className="mr-2" />
              {customMessage}
            </span>
          )}
          {customMessageType === CustomMessageType.Warning && (
            <span className="form-element-warning-message text-xs text-yellow-600">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
              {customMessage}
            </span>
          )}
        </div>
      )}

      {errors && (
        <div>
          <span className="form-element-error-message text-xs text-red-500">
            {errors?.message != null && (errors?.message as any) != '' ? (
              (errors?.message as any)
            ) : (
              <>
                {(errors?.type as any) === 'required' && t('common:Validation.required')}
                {(errors?.type as any) === 'minLength' && t('common:Validation.minLength', { minLength })}
                {(errors?.type as any) === 'maxLength' && t('common:Validation.maxLength', { maxLength })}
                {(errors?.type as any) === 'min' && t('common:Validation.minValue', { minValue })}
                {(errors?.type as any) === 'max' && t('common:Validation.maxValue', { maxValue })}
                {(errors?.type as any) === 'pattern' &&
                  (customPatternErrorMessage ? customPatternErrorMessage : t('common:Validation.pattern'))}
              </>
            )}
          </span>
        </div>
      )}
    </>
  )
}
