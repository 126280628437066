import { Country } from '@arland-bmnext/api-data'
import useTranslation from 'next-translate/useTranslation'
import React, { ReactNode, useEffect, useState } from 'react'
import {
  FieldErrors,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form'
import { useLayoutContext } from '../../context/layout.context'
import { SelectOption } from '../../util/select-option'
import { ContainedButton, OutlineButton } from '../core/Button'
import { ErrorMessage, SuccessMessage } from '../core/Message'
import { SimpleMultiStep } from '../core/MultiStep'
import { HelpTextProps } from '../HelpText'
import { FormDivider } from './elements/Divider'
import SectionTitle from './elements/SectionTitle'
import FormElement from './FormElement'

type FormFieldsProps = {
  formFields: FormFieldConfig[]
  defaultValues: any
  register: UseFormRegister<any>
  setValue: UseFormSetValue<any>
  getValues: UseFormGetValues<any>
  trigger: UseFormTrigger<any>
  grid: string
  errors: FieldErrors
}

const FormFields = ({
  formFields,
  defaultValues,
  register,
  setValue,
  getValues,
  trigger,
  grid,
  errors,
}: FormFieldsProps) => {
  const getFormFieldByFieldType = (field: FormFieldConfig) => {
    switch (field.formFieldType) {
      case FormFieldType.Input:
      case FormFieldType.PhoneNumber:
      case FormFieldType.Select:
      case FormFieldType.TextView:
      case FormFieldType.SelflimitInput:
      case FormFieldType.AmountSelector:
      case FormFieldType.DescriptiveInput:
      case FormFieldType.RadioGroupElement:
      case FormFieldType.OddsFormatSelect:
      case FormFieldType.DateSelect:
        return (
          <FormElement
            label={field.name}
            placeholder={field.placeholder}
            property={field.property}
            required={field.required ?? false}
            showOptionalIfNotRequired={field.showOptionalIfNotRequired}
            register={register}
            setValue={setValue}
            getValues={getValues}
            trigger={trigger}
            validators={field.validators}
            formFieldType={field.formFieldType}
            type={field.type ?? 'text'}
            inputType={field.inputType ?? 'text'}
            accept={field.accept}
            errors={errors[field.property] as any}
            selectOptions={field.selectOptions}
            minValue={field.minValue}
            maxValue={field.maxValue}
            minLength={field.minLength}
            maxLength={field.maxLength}
            pattern={field.pattern}
            autocomplete={field.autocomplete}
            readonly={field.readonly}
            disabled={field.disabled}
            prefix={field.prefix}
            postfix={field.postfix}
            customMessage={field.customMessage}
            customMessageType={field.customMessageType}
            customPatternErrorMessage={field.customPatternErrorMessage}
            value={field.value ?? (defaultValues ? defaultValues[field.property] : null) ?? undefined}
            additionalActionText={field.additionalActionText}
            additionalActionFn={field.additionalActionFn}
            additionalActionOnlyIfValid={field.additionalActionOnlyIfValid ?? false}
            additionalActionOnlyIfNotEmpty={field.additionalActionOnlyIfNotEmpty ?? false}
            helpText={field.helpText}
            usedUp={field.usedUp}
            currentLimit={field.currentLimit}
            nextPossibleIncreasement={field.nextPossibleIncreasement}
            additionalClassNames={field.additionalClassNames}
            showLabel={field.showLabel}
            fixedCountryCallingCode={field.fixedCountryCallingCode}
            countries={field.countries}
          />
        )
      case FormFieldType.Divider:
        return <FormDivider />
      case FormFieldType.SectionTitle:
        return (
          <SectionTitle
            title={field.name}
            subtitle={field.subtitle}
            additionalClassNames={field.additionalClassNames}
          />
        )
      case FormFieldType.ReactComponent:
        return field.reactComponent
    }
  }

  return (
    <div className={`form-fields space-y-6 ${grid}`}>
      {formFields.map((field: FormFieldConfig, index: number) => {
        return (
          <React.Fragment key={field.property != null && field.property != '' ? field.property : index}>
            {getFormFieldByFieldType(field)}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export enum CustomMessageType {
  Success,
  Error,
  Warning,
}

export type FormFieldConfig = {
  name?: string
  subtitle?: string
  description?: string
  placeholder?: string
  property?: string
  validators?: any
  required?: boolean
  showOptionalIfNotRequired?: boolean
  formFieldType?: FormFieldType
  type?: string
  inputType?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal'
  accept?: string
  selectOptions?: SelectOption[]
  minValue?: string | number
  maxValue?: string | number
  minLength?: number
  maxLength?: number
  pattern?: RegExp
  autocomplete?: string
  readonly?: boolean
  disabled?: boolean
  prefix?: string
  postfix?: string
  customMessage?: string
  customMessageType?: CustomMessageType
  customPatternErrorMessage?: string
  value?: any
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  additionalActionOnlyIfValid?: boolean
  additionalActionOnlyIfNotEmpty?: boolean
  helpText?: HelpTextProps
  usedUp?: number
  currentLimit?: number
  nextPossibleIncreasement?: string
  additionalClassNames?: string
  showLabel?: boolean
  reactComponent?: ReactNode
  fixedCountryCallingCode?: any
  countries?: Country[]
}

export enum FormFieldType {
  Input,
  PhoneNumber,
  Select,
  TextView,
  Divider,
  SectionTitle,
  SelflimitInput,
  AmountSelector,
  DescriptiveInput,
  RadioGroupElement,
  ReactComponent,
  OddsFormatSelect,
  DateSelect,
}

export type FormBuilderProps = {
  _handleSubmit: UseFormHandleSubmit<any> | any
  _register: UseFormRegister<any>
  _setValue?: UseFormSetValue<any>
  _getValues?: UseFormGetValues<any>
  _getFieldState?: UseFormGetFieldState<any>
  _trigger?: UseFormTrigger<any>
  _errors: FieldErrors
  _isValid: boolean
  _fields: FormFieldConfig[]
  _defaultValues?: any
  _submitText: string
  _onSubmit?: ((args: any) => Promise<any>) | ((args: any) => any)
  _errorMessage?: string
  _errorMessageAction?: JSX.Element
  _successMessage?: string
  _setLoadingStateOnSubmit?: boolean
  _isMultiStepForm?: boolean
  _multiStepFormFirstStepText?: string
  _multiStepFormFirstStepDescription?: string
  _multiStepFormLastStepText?: string
  _multiStepFormLastStepDescription?: string
  _grid?: string
}

export const FormBuilder = ({
  _handleSubmit,
  _register,
  _setValue,
  _getValues,
  _getFieldState,
  _trigger,
  _errors,
  _isValid,
  _fields,
  _defaultValues,
  _submitText,
  _onSubmit = undefined,
  _errorMessage = undefined,
  _errorMessageAction = undefined,
  _successMessage = undefined,
  _setLoadingStateOnSubmit = true,
  _isMultiStepForm = false,
  _multiStepFormFirstStepText = '',
  _multiStepFormFirstStepDescription = '',
  _multiStepFormLastStepText = '',
  _multiStepFormLastStepDescription = '',
  _grid = '',
}: FormBuilderProps) => {
  const layoutContext = useLayoutContext()
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState(0)
  const [currentStepFields, setCurrentStepFields] = useState<FormFieldConfig[]>([])
  const [maxSteps, setMaxSteps] = useState(0)

  useEffect(() => {
    if (_fields?.length > 0) setMaxSteps(_fields.filter((x) => x.formFieldType === FormFieldType.Divider).length)
  }, [_fields])

  useEffect(() => {
    if (_isMultiStepForm && _fields?.length > 0) {
      getCurrentStepFields()
    }
  }, [currentStep, _fields])

  const getCurrentStepFields = () => {
    const fields = []
    let dividersReached = 0
    for (let field of _fields) {
      if (dividersReached > currentStep) {
        break
      }
      if (field.formFieldType === FormFieldType.Divider) {
        dividersReached++
        continue
      }
      if (dividersReached === currentStep) {
        fields.push(field)
      }
    }
    setCurrentStepFields(fields)
  }

  const onFormSubmit = async (props) => {
    try {
      if (_setLoadingStateOnSubmit) layoutContext.setLoadingState(true)
      await _onSubmit(props)
    } finally {
      if (_setLoadingStateOnSubmit) layoutContext.setLoadingState(false)
      window.scrollTo(0, 0)
    }
  }

  const onNextStep = () => {
    if (currentStep < maxSteps) {
      setCurrentStep(currentStep + 1)
      window.scrollTo(0, 0)
    }
  }

  const onPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1)
      window.scrollTo(0, 0)
    }
  }

  const isCurrentStepValid = () => {
    let valid = true
    for (let field of currentStepFields) {
      const fieldValue = _getValues(field.property)
      const fieldState = _getFieldState(field.property)
      if (
        fieldState.invalid ||
        fieldState.error != null ||
        (field.required && !fieldState.isTouched && (fieldValue == null || fieldValue == ''))
      ) {
        valid = false
        break
      }
    }
    return valid
  }

  return (
    <form className="form-builder" onSubmit={_handleSubmit(onFormSubmit)}>
      {_isMultiStepForm && (
        <SimpleMultiStep
          steps={maxSteps + 1}
          currentStep={currentStep + 1}
          className="mb-8"
          firstStepText={_multiStepFormFirstStepText}
          firstStepDescription={_multiStepFormFirstStepDescription}
          lastStepText={_multiStepFormLastStepText}
          lastStepDescription={_multiStepFormLastStepDescription}
        />
      )}

      {_successMessage && <SuccessMessage message={_successMessage} />}

      {_errorMessage && <ErrorMessage message={_errorMessage}>{_errorMessageAction}</ErrorMessage>}

      <>
        {_fields.length > 0 && (
          <FormFields
            register={_register}
            setValue={_setValue}
            getValues={_getValues}
            trigger={_trigger}
            formFields={_isMultiStepForm ? currentStepFields : _fields}
            defaultValues={_defaultValues}
            grid={_grid}
            errors={_errors}
          />
        )}

        <div className="form-builder-controls flex justify-end space-x-2 pt-8">
          {_isMultiStepForm && _fields.length > 0 && (
            <>
              {currentStep > 0 && (
                <OutlineButton
                  onClick={onPreviousStep}
                  borderColor="border-none"
                  className="form-builder-control-back opacity-70"
                  rounded
                >
                  {t('common:Common.back')}
                </OutlineButton>
              )}

              {currentStep < maxSteps && (
                <ContainedButton
                  onClick={onNextStep}
                  className="form-builder-control-next flex-grow"
                  backgroundColor="bg-primary"
                  borderColor="border-none"
                  disabled={!isCurrentStepValid()}
                  rounded
                >
                  {t('common:Common.next')}
                </ContainedButton>
              )}
            </>
          )}

          {(!_isMultiStepForm || currentStep === maxSteps) && _onSubmit && _submitText && (
            <ContainedButton
              type="submit"
              className="form-builder-control-submit"
              backgroundColor="bg-primary"
              borderColor="border-none"
              disabled={!_isValid}
              rounded
              fullWidth
            >
              {_submitText}
            </ContainedButton>
          )}
        </div>
      </>
    </form>
  )
}
