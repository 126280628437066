import { Currency } from "@arland-bmnext/api-data"
import { useCommonContext } from "../context/common.context"
import { getCurrencyIdForUser, useUser, useUserAccounts } from "../lib/user"

const useUserCurrency = (): Currency => {
  const { user } = useUser()
  const { mainAccount } = useUserAccounts()
  const { getCurrencyById } = useCommonContext()
  const currency = getCurrencyById(getCurrencyIdForUser(user, mainAccount))

  return currency
}

export default useUserCurrency
