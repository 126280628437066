import { UseFormRegister } from 'react-hook-form'
import { FormElementLabel } from './Input'

export type TextViewProps = {
  label: string
  property: string
  register: UseFormRegister<any>
  value: any
  additionalActionText?: string
  additionalActionFn?: (...args) => any
}

const TextView = ({
  label,
  property,
  register,
  value,
  additionalActionText = '',
  additionalActionFn = null,
}: TextViewProps) => {
  return (
    <div className="form-text-view flex flex-col mb-4">
      <FormElementLabel label={label} property={property} />

      <div className="form-text-view-inner relative">
        <span
          className={`form-text-view-text flex w-full bg-form text-formContrast text-sm border-[.5px] border-formBorder rounded-md min-h-[42px] px-4 py-[10px]`}
          name={property}
          id={property}
          {...register(property)}
        >
          {value}
        </span>
      </div>

      {additionalActionText && (
        <div
          className="form-text-view-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}
    </div>
  )
}

export default TextView
