import { BranchPasswordPolicySettings, UserIdentificationType } from '@arland-bmnext/api-data'
import { Translate } from 'next-translate'
import { UseFormWatch } from 'react-hook-form'
import isValidPhoneNumber from 'libphonenumber-js'
import dayjs from 'dayjs'
import { isUserIdentifierUnique } from '../lib/user'
import { countDecimals } from './number'
import debounce from 'lodash.debounce'

export const PasswordValidator = (t: Translate, passwordPolicy: BranchPasswordPolicySettings) => {
  const validator = {} as any

  validator.passwordPolicyMinLength = (value: string) => {
    if (value.length < passwordPolicy.minLength)
      return t('common:Validation.passwordPolicyMinLength', { minLength: passwordPolicy.minLength })
  }

  validator.passwordPolicyMaxLength = (value: string) => {
    if (value.length > passwordPolicy.maxLength)
      return t('common:Validation.passwordPolicyMaxLength', { maxLength: passwordPolicy.maxLength })
  }

  validator.passwordPolicyRequiresUppercaseAndLowercase = (value: string) => {
    if (!passwordPolicy.requiresUppercaseAndLowercase) return true

    let lowerCaseCount: number = 0
    let upperCaseCount: number = 0

    value.split('').forEach((char) => {
      if (char === char.toLowerCase()) {
        lowerCaseCount++
      } else if (char === char.toUpperCase()) {
        upperCaseCount++
      }
    })

    if (lowerCaseCount === 0 || upperCaseCount === 0)
      return t('common:Validation.passwordPolicyRequiresUppercaseAndLowercase')
  }

  validator.passwordPolicyRequiredNumber = (value: string) => {
    if (passwordPolicy.requiredNumbers === 0) return true

    let numbersCount: number = 0

    value.split('').forEach((char) => {
      if (!isNaN(parseFloat(char) - 0)) {
        numbersCount++
      }
    })

    if (numbersCount < passwordPolicy.requiredNumbers)
      return t('common:Validation.passwordPolicyRequiredNumber', { requiredNumbers: passwordPolicy.requiredNumbers })
  }

  validator.passwordPolicyRequiredSymbols = (value: string) => {
    if (passwordPolicy.requiredSymbols === 0) return true

    const symbols = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
    let symbolsCount: number = 0

    value.split('').forEach((char) => {
      if (symbols.test(char)) {
        symbolsCount++
      }
    })

    if (symbolsCount < passwordPolicy.requiredSymbols)
      return t('common:Validation.passwordPolicyRequiredSymbols', {
        requiredSymbols: passwordPolicy.requiredSymbols,
        allowedSymbols: passwordPolicy.allowedSymbols,
      })
  }

  validator.passwordPolicyNotAllowedSymbols = (value: string) => {
    const symbols = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/
    let notAllowedSymbolsFound: boolean = false

    value.split('').forEach((char) => {
      if (symbols.test(char) && !passwordPolicy.allowedSymbols?.includes(char)) {
        notAllowedSymbolsFound = true
      }
    })

    if (notAllowedSymbolsFound) return t('common:Validation.passwordPolicyNotAllowedSymbols')
  }

  validator.passwordPolicyNotAllowedWhiteSpaces = (value: string) => {
    if (passwordPolicy.allowWhiteSpace) return true

    let whiteSpacesFound: boolean = false
    value.split('').forEach((char) => {
      if (char === ' ') whiteSpacesFound = true
    })

    if (whiteSpacesFound) return t('common:Validation.passwordPolicyNotAllowedWhiteSpaces')
  }

  return validator
}

export const PasswordRepeatValidator = (t: Translate, watch: UseFormWatch<any>, passwordFieldName: string) => {
  return {
    passwordsDoNotMatch: (value: string) => {
      const watchedValue = watch<string>(passwordFieldName, '')
      if (value !== watchedValue) return t('common:Validation.passwordsDoNotMatch')
      return true
    },
  }
}

export const PinRepeatValidator = (t: Translate, watch: UseFormWatch<any>, pinFieldName: string) => {
  return {
    pinsDoNotMatch: (value: string) => {
      const watchedValue = watch<string>(pinFieldName, '')
      if (value !== watchedValue) return t('common:Validation.pinsDoNotMatch')
      return true
    },
  }
}

export const PhoneNumberValidator = (t: Translate) => {
  return {
    phoneNumberInvalid: (value: string) => {
      const valid = isValidPhoneNumber(value)
      if (!valid) return t('common:Validation.phoneNumberInvalid')
      return true
    },
  }
}

export const BirthdayValidator = (t: Translate, minAge: number = 18) => {
  return {
    birthdayValid: (value: Date | string) => {
      const date = dayjs(new Date(value)).startOf('day')
      const now = dayjs().startOf('day')

      const maxDate = now.add(-minAge, 'years').startOf('day')
      const diffDays = maxDate.diff(date, 'days')

      return diffDays >= 0 ? true : t('common:Validation.birthdayInvalid', { minAge })
    },
  }
}

export const UniqueEmailValidator = (t: Translate) => {
  return {
    emailUnique: debounce(async (value: string) => {
      const isUnique = await isUserIdentifierUnique(UserIdentificationType.EmailAddress, value)
      return isUnique ? true : t('common:Validation.emailTaken')
    }, 1000),
  }
}

export const UniqueLogonNameValidator = (t: Translate) => {
  return {
    logonNameUnique: debounce(async (value: string) => {
      const isUnique = await isUserIdentifierUnique(UserIdentificationType.Username, value)
      return isUnique ? true : t('common:Validation.logonNameTaken')
    }, 1000),
  }
}

export const UniqueNationalIdValidator = (t: Translate) => {
  return {
    nationalIdUnique: debounce(async (value: string) => {
      const isUnique = await isUserIdentifierUnique(UserIdentificationType.NationalIdentityId, value)
      return isUnique ? true : t('common:Validation.nationalIdTaken')
    }, 1000),
  }
}

export const FileValidator = (t: Translate, maxByteSize: number = null, allowedTypes: string[] = null) => {
  return {
    maxFileSizeExceeded: (value: FileList) => {
      const file = value[0]
      if (!file || !maxByteSize) return
      return file.size <= maxByteSize ? true : t('common:Validation.maxFileSizeExceeded')
    },
    fileTypeNotAllowed: (value: FileList) => {
      const file = value[0]
      if (!file || !allowedTypes) return true
      return allowedTypes.includes(file.type) ? true : t('common:Validation.fileTypeNotAllowed')
    },
  }
}

export const MaxDecimalPlaces = (t: Translate, maxDecimalPlaces: number = null) => {
  return {
    maxDecimalPlacesExceeded: (value: number | string) => {
      return countDecimals(value) <= maxDecimalPlaces ? true : t('common:Validation.maxDecimalPlacesExceeded')
    },
  }
}

export const BooleanValidator = (targetValue: boolean, message: string) => {
  return {
    booleanValidationFailed: (value: boolean) => {
      return value != null && value === targetValue ? true : message
    },
  }
}

export const EmailPattern =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
