import { faCheckCircle, faTimesCircle, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export type MessageProps = {
  message?: string
  children?: any
  className?: string
}

export const SuccessMessage = ({ message = null, children = null, className = '' }: MessageProps) => {
  return (
    <div className={'success-message flex items-center text-white p-4 bg-green-800 mb-4 rounded-md text-sm ' + className}>
      <FontAwesomeIcon icon={faCheckCircle} className="mr-4" />
      {message && message}
      {children && children}
    </div>
  )
}

export const ErrorMessage = ({ message = null, children = null, className = '' }: MessageProps) => {
  return (
    <div className={'error-message flex items-center text-white p-4 bg-red-800 mb-4 rounded-md text-sm ' + className}>
      <FontAwesomeIcon icon={faTimesCircle} className="mr-4" />
      <div className="flex flex-col space-y-2">
        {message && <p>{message}</p>}
        {children && children}
      </div>
    </div>
  )
}

export const WarningMessage = ({ message = null, children = null, className = '' }: MessageProps) => {
  return (
    <div className={'warning-message flex items-center text-white p-4 bg-yellow-300 mb-4 rounded-md text-sm ' + className}>
      <FontAwesomeIcon icon={faTriangleExclamation} className="mr-4" />
      <div className="flex flex-col space-y-2">
        {message && <p>{message}</p>}
        {children && children}
      </div>
    </div>
  )
}
