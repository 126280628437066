import React, { useState } from 'react'
import { FieldErrors, UseFormRegister } from 'react-hook-form'
import { SelectOption } from '../../../util/select-option'
import { RadioGroup } from '../../core/RadioGroup'
import { HelpTextProps } from '../../HelpText'
import { FormElementErrorMessage, FormElementLabel } from './Input'

export type RadioProps = {
  label?: string
  required?: boolean
  showOptionalIfNotRequired: boolean
  property?: string
  selectOptions?: SelectOption[]
  validators?: any
  errors?: FieldErrors
  register?: UseFormRegister<any>
  value?: any
  onChange?: (...args: any) => void
  additionalActionText?: string
  additionalActionFn?: (...args) => any
  helpText?: HelpTextProps
  placeholder?: any
}

export const RadioGroupElement = ({
  label = '',
  required = false,
  showOptionalIfNotRequired = true,
  property = undefined,
  selectOptions = [],
  validators = {},
  errors = undefined,
  register = (...args) => null,
  setValue = (...args) => null,
  onChange = (...args) => null,
  value = '',
  additionalActionText = '',
  additionalActionFn = null,
  helpText = null,
}) => {
  const [currentValue, setCurrentValue] = useState(value)

  const _onChange = (ev) => {
    setCurrentValue(ev)
    onChange(ev)

    value = ev

    // set value manually, otherwise not recognized by react-hook-forms
    setValue(property, value, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  return (
    <div className="radio-group flex flex-col mb-4 relative w-full">
      <FormElementLabel
        label={label}
        property={property}
        required={required}
        showOptionalIfNotRequired={showOptionalIfNotRequired}
        helpText={helpText}
      />

      <div className="radio-group-inner items-center max-w-full">
        <RadioGroup options={selectOptions} selectedValue={currentValue} onChange={_onChange} addBottomMargin={false} />
        <input
          type="hidden"
          id={property}
          name={property}
          value={currentValue}
          {...register(property, {
            required: required,
            validate: validators,
          })}
        />
      </div>

      {additionalActionText && (
        <div
          className="radio-group-additional-action flex justify-end w-full text-primary text-xs font-semibold mt-2 cursor-pointer"
          onClick={additionalActionFn}
        >
          {additionalActionText}
        </div>
      )}
      <FormElementErrorMessage errors={errors} />
    </div>
  )
}
